<template>
    <div class="gpu-recommendation-interface">
        <select v-model="selectedCompetitor" @change="checkSelections" id="tableFilter" class="form-select" title="Select Graphics Card">
            <option value="">{{ tablePrompt }}</option>
            <option v-for="item in uniqueItems('Competitor_Card', scoreItems)" :key="item['Competitor_Card']" :value="item['Competitor_Card']">{{ item['Competitor_Card'] }}</option>
        </select>
    </div>
    <div class="gpu-recommendation-cards" >
        <a v-for="(item, index) in uniqueItems('AMD_Card', cardList)" :key="item['AMD_Card']" href="#!" :class="{'active': index === activeIndex}" @click="toggleMetric(index, item['AMD_Card'], item.Resolution)" >{{ item['AMD_Card'] }}</a>
    </div>
    <div class="gpu-recommendation-chart">
        <canvas id="gameChart"></canvas>
    </div>
    <div class="gpu-recommendation-info" v-if="chartActive">
        <dl>
            <dt>Upgrade to</dt>
            <div class="gpu-info">
                <dd id="card-performance">{{ this.performance }}% faster gaming (on avg.)</dd>
                <dd v-if="this.memory" id="card-memory">{{ this.memory }} more video memory</dd>
                <dd id="card-name">with {{ this.selectedAmdCard }} graphics card</dd>
            </div>
        </dl>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
export default {
    inject: ['scores', 'info', 'tablePrompt'],
    data() {
        return {
            scoreItems: this.scores,
            infoItems: this.info,
            tablePrompt: this.tablePrompt,
            selectedCompetitor: '',
            selectedAmdCard: '',
            resolution: '',
            performance: '',
            memory: '',
            activeIndex: 0,
            chartActive: false,
        }
    },
    computed: {
        cardList() {
            const cardList = this.scoreItems.filter((item) =>
                item['Competitor_Card'] === this.selectedCompetitor
            );

            return cardList;
        },
        finalFilter() {
            return this.scoreItems.filter((item) =>
                item['Competitor_Card'] === this.selectedCompetitor &&
                item['AMD_Card'] === this.selectedAmdCard
            );
        }
    },
    methods: {
        uniqueItems(property, itemList) {
            const unique = new Set();
            return itemList.filter(item => {
                if (unique.has(item[property])) {
                    return false;
                }
                unique.add(item[property]);
                return true;
            });
        },
        checkSelections() {
            if (this.selectedCompetitor) {
                let cards = this.uniqueItems('AMD_Card', this.cardList);
                this.selectedAmdCard = cards[0]['AMD_Card'];
                this.resolution = cards[0].Resolution;
                this.performance = this.getInfo('Performance');
                this.memory = this.getInfo('Memory');
                this.activeIndex = 0;
                this.buildChart();
            }
            else if (this.chartActive) {
                this.recommendChart.destroy();
                this.chartActive = false;
            }
        },
        getInfo(property) {
            const info = this.infoItems.filter((item) =>
                item['Competitor_Card'] === this.selectedCompetitor && item['AMD_Card'] === this.selectedAmdCard
            );

            return info[0][property];
        },
        getAspect() {
            return Math.min(Math.max(window.innerWidth / window.innerHeight, 1), 2) * 1.33;
        },
        getDataset() {
            return this.finalFilter;
        },
        sortData() {
            const data = this.getDataset();
            data.sort(function(a,b) {
                const val1 = Number(a.Score);
                const val2 = Number(b.Score);

                if (val1 > val2) {
                    return -1;
                }
                if (val1 < val2) {
                    return 1;
                }
                return 0;
            });

            return data;
        },
        toggleMetric(index, card, resolution) {
            this.activeIndex = index;
            this.selectedAmdCard = card;
            this.resolution = resolution;
            this.performance = this.getInfo('Performance');
            this.memory = this.getInfo('Memory');
            this.buildChart();
        },
        getMaxWidth() {
            const scoreValues = this.getDataset().map(row => row.Score);
            const maxValue = Math.max(...scoreValues);
            const buffer = maxValue * 0.1;
            return maxValue + buffer;
        },
        dynamicFontSize() {
            let windowWidth = window.innerWidth;
            return {
                size: windowWidth < 375 ? 8 : (windowWidth < 412 ? 10 :(windowWidth < 1241 ? 12 : (windowWidth < 1280 ? 14 : 16))),
                lineHeight: 1
            }
        },
        buildChart() {
            const that = this;

            if (this.chartActive) {
                this.recommendChart.destroy();
            }
            else {
                this.chartActive = true;
            }

            // Global Chart Values
            Chart.defaults.font.size = 16;
            Chart.defaults.font.family = "Open Sans,arial,sans-serif";
            let gridColor = 'rgba(0,0,0,0.1)';
            if (document.querySelectorAll('.gpuRecommender.text-light').length > 0) {
                Chart.defaults.color = '#FFF';
                gridColor = 'rgba(255,255,255,0.2)';
            }
            else {
                Chart.defaults.color = '#000';
            }

            // Create the Chart.
            this.recommendChart = new Chart(document.getElementById('gameChart'), {
                plugins: [ChartDataLabels],
                type: 'bar',
                data: {
                    labels: this.sortData().map(row => row.Game),
                    datasets: [
                        {
                            label: this.selectedAmdCard,
                            data: this.sortData().map(row => row.Score),
                            backgroundColor: 'rgba(237,28,36,1)',
                            datalabels: {
                                anchor: 'end',
                                align: 'end',
                            }
                        }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Avg. FPS at ' + this.resolution + ' Max Settings\u00B9 | % Faster vs. ' + this.selectedCompetitor
                        },
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.dataset.label + ': ' + context.formattedValue + '%';
                                }
                            }
                        },
                        datalabels: {
                            formatter: function(value, context) {
                                return value + '%';
                            },
                            font: this.dynamicFontSize()
                        }
                    },
                    indexAxis: 'y',
                    aspectRatio: this.getAspect(),
                    scales: {
                        x: {
                            grid: {
                                color: gridColor
                            },
                            suggestedMax: this.getMaxWidth()
                        },
                        y: {
                            grid: {
                                color: gridColor
                            },
                            ticks: {
                                font: this.dynamicFontSize()
                            }
                        }
                    },
                },
            });

            // Chart Click and Hover
            if (this.chartActive) {
                const gpuCanvas = document.querySelector('#gameChart');

                gpuCanvas.onmousemove = (event) => {
                    let elements = that.recommendChart.getElementsAtEventForMode(event, 'y', {intersect: false}, true);

                    if (elements.length) {
                        let label = that.recommendChart.data.labels[elements[0].index];
                        gpuCanvas.style.cursor = 'default';
                    }
                    else {
                        gpuCanvas.style.cursor = 'default';
                    }
                };

                gpuCanvas.onclick = (event) => {
                    let elements = that.recommendChart.getElementsAtEventForMode(event, 'y', {intersect: false}, true);

                    if (elements.length) {
                        let label = that.recommendChart.data.labels[elements[0].index];
                    }
                };
            }
        },
    }
};

</script>
