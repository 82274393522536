import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';
import { createApp } from 'vue';
import gpuRecommenderTemplate from './gpuRecommender.vue';

class gpuRecommender extends AEM.Component {
    init() {
        this.showRecommendation();
    }
    async fetchBenchmarksJSON(benchmarksJSON) {
        const response = await fetch(benchmarksJSON);
        const data = await response.json();
        return data;
    }
    showRecommendation() {
        const gpuRecommenderComponent = createApp(gpuRecommenderTemplate);
        const gpuRecommenderPlaceholder = this.element.querySelector('[data-scores-json]');
        if (gpuRecommenderPlaceholder) {
            const tablePrompt = gpuRecommenderPlaceholder.dataset.tablePrompt === undefined ? 'Select Category' : gpuRecommenderPlaceholder.dataset.tablePrompt;

            gpuRecommenderComponent.provide('tablePrompt', tablePrompt);

            const scoresData = this.fetchBenchmarksJSON(gpuRecommenderPlaceholder.dataset.scoresJson);
            const infoData = this.fetchBenchmarksJSON(gpuRecommenderPlaceholder.dataset.infoJson);

            Promise.all([ scoresData, infoData ])
                .then(([ data1, data2 ]) => {
                    gpuRecommenderComponent.provide('scores', data1);
                    gpuRecommenderComponent.provide('info', data2);
                    gpuRecommenderComponent.mount(gpuRecommenderPlaceholder);
                });
        }
    }
}

export { gpuRecommender };
